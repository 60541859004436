import { Money, MoneyStorage, formatStorageType } from "@monet-money/money-type";
import { GridColDef, GridRenderCellParams } from "@mui/x-data-grid";
import dayjs from "dayjs";
import { DEFAULT_DATE_FORMAT } from "shared/logic/calculate";
import { SYSTEM_ALLOWED_CURRENCIES } from "shared/utils/currency";
import { invoiceStatuses } from "shared/utils/invoice";

type InvoiceData = {
  rowId: string;
  partnerId: string;
  campaignId: string;
  campaignName: string;
  invoiceId: string;
  invoiceNumber: number;
  invoiceStatus: string;
  dueDate: number;
  daysOverdue: number;
  invoiceCurrency: string;
  invoiceValue: MoneyStorage;
  fundedAmount: MoneyStorage;
  dailyLateFee: MoneyStorage;
  totalLateFee: MoneyStorage;
};

export const overdueInvoiceSummaryCols: GridColDef<InvoiceData>[] = [
  {
    field: "campaignName",
    headerName: "Campaign name",
    type: "string",
    width: 200,
  },
  {
    field: "invoiceNumber",
    headerName: "Invoice number",
    type: "string",
    minWidth: 150,
  },
  {
    field: "invoiceStatus",
    headerName: "Invoice status",
    type: "singleSelect",
    valueOptions: invoiceStatuses,
    minWidth: 150,
  },
  {
    field: "dueDate",
    headerName: "Due date",
    type: "string",
    minWidth: 150,
    valueGetter: (value, row) => (value ? dayjs(value, DEFAULT_DATE_FORMAT).toDate() : undefined),
    valueFormatter: (value, row) => (value ? dayjs(value).format(DEFAULT_DATE_FORMAT) : undefined),
  },
  {
    field: "daysOverdue",
    headerName: "Days overdue",
    type: "number",
    minWidth: 150,
  },
  {
    field: "invoiceCurrency",
    headerName: "Invoice currency",
    type: "singleSelect",
    valueOptions: SYSTEM_ALLOWED_CURRENCIES,
    minWidth: 150,
  },
  {
    field: "invoiceValue",
    headerName: "Invoice value",
    type: "number",
    minWidth: 150,
    valueGetter: (value, row) => {
      return Number(Money.fromStorageType(value).format(false, false));
    },
    renderCell: (params: GridRenderCellParams<InvoiceData>) => {
      return formatStorageType(params.row.invoiceValue as MoneyStorage);
    },
  },
  {
    field: "fundedAmount",
    headerName: "Funded amount",
    type: "number",
    minWidth: 150,
    valueGetter: (value, row) => {
      return Number(Money.fromStorageType(value).format(false, false));
    },
    renderCell: (params: GridRenderCellParams<InvoiceData>) => {
      return formatStorageType(params.row.fundedAmount as MoneyStorage);
    },
  },
  {
    field: "dailyLateFee",
    headerName: "Daily late fee",
    type: "number",
    minWidth: 150,
    valueGetter: (value, row) => {
      return Number(Money.fromStorageType(value).format(false, false));
    },
    renderCell: (params: GridRenderCellParams<InvoiceData>) => {
      return formatStorageType(params.row.dailyLateFee as MoneyStorage);
    },
  },
  {
    field: "totalLateFee",
    headerName: "Total late fee",
    type: "number",
    minWidth: 150,
    valueGetter: (value, row) => {
      return Number(Money.fromStorageType(value).format(false, false));
    },
    renderCell: (params: GridRenderCellParams<InvoiceData>) => {
      return formatStorageType(params.row.totalLateFee as MoneyStorage);
    },
  },
  {
    field: "partnerId",
    headerName: "Partner ID",
    type: "string",
    width: 200,
  },
  {
    field: "campaignId",
    headerName: "Campaign ID",
    type: "string",
    width: 200,
  },
  {
    field: "invoiceId",
    headerName: "Invoice ID",
    type: "string",
    width: 150,
  },
];

import { styled } from "@mui/material";
import { DataGrid, GridColumnVisibilityModel, GridToolbar } from "@mui/x-data-grid";
import MonetButton from "components/tailwind/MonetButton";
import MonetCard from "components/tailwind/MonetCard";
import MonetViewTitle from "components/tailwind/MonetViewTitle";
import MonetDatePicker from "components/tailwind/form/MonetDatePicker";
import TailwindSelectInput from "components/tailwind/headlessTailwind/TailwindSelectInput";
import { useSessionProvider } from "contexts/SessionProvider";
import dayjs from "dayjs";
import { useEffect, useMemo, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { toast } from "sonner";
import { isInAdminContext } from "utils/User";
import { balanceSheetCols } from "./columnDefinitions/balanceSheetCols";
import { campaignSummaryCols } from "./columnDefinitions/campaignSummaryCols";
import { invoiceSettlementSummaryCols } from "./columnDefinitions/invoiceSettlementCols";
import { invoiceSummaryCols } from "./columnDefinitions/invoiceSummaryCols";
import { loanTapeCols } from "./columnDefinitions/loanTapeCols";
import { overdueInvoiceSummaryCols } from "./columnDefinitions/overdueInvoiceSummaryCols";
import { payoutSummaryCols } from "./columnDefinitions/payoutSummaryCols";
import { profitLossCols } from "./columnDefinitions/profitLossCols";
import { useGetReportLazyQuery } from "./graphql/queries.generated";

const StyledDataGrid = styled(DataGrid)(({ theme }) => ({
  border: "none",

  "& .MuiButtonBase-root.Mui-disabled": {
    color: theme.palette.grey[400],
  },
  "& .MuiDataGrid-main, .MuiDataGrid-footerContainer": {
    backgroundColor: "#ffffff",
    border: "none",
  },
  "& .MuiDataGrid-row": {
    "&:nth-of-type(even)": {
      backgroundColor: theme.palette.grey[50],
    },
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.common.white,
    },
  },
}));

const Reports = () => {
  const { user } = useSessionProvider();

  const [showStartDate, setShowStartDate] = useState(false);
  const [showEndDate, setShowEndDate] = useState(false);

  const [reportData, setReportData] = useState<Array<any>>([]);
  const [columnVisibilityModel, setColumnVisibilityModel] = useState<GridColumnVisibilityModel>({
    partnerId: isInAdminContext(user),
    fundingProcessedBy: isInAdminContext(user),
    approvedBy: isInAdminContext(user),
    underwritingNotes: isInAdminContext(user),
    experianRiskScore: isInAdminContext(user),
    wiserfundingRiskScore: isInAdminContext(user),
    settlementBy: isInAdminContext(user),
  });

  const [getReport, { loading, error, refetch }] = useGetReportLazyQuery({ fetchPolicy: "cache-first" });

  const methods = useForm<{ reportType: string; startDate?: Date; endDate?: Date }>({
    mode: "onTouched",
    defaultValues: {
      endDate: dayjs().toDate(),
    },
  });

  const onSubmit = async (formData: { reportType: string; startDate?: Date; endDate?: Date }) => {
    if (formData) {
      await getReport({
        variables: {
          reportType: formData.reportType,
          startDate: formData.startDate ? dayjs(formData.startDate).toISOString() : undefined,
          endDate: formData.endDate ? dayjs(formData.endDate).toISOString() : undefined,
        },
      }).then((res) => {
        const result = res.data?.getReport;
        if (result) {
          setReportData(JSON.parse(result.data));
        }
      });
    }
  };

  useEffect(() => {
    if (error) {
      toast.error("Request failed", { description: error.message });
    }
  }, [error]);

  const reportType = methods.watch("reportType");
  const startDate = methods.watch("startDate");

  useEffect(() => {
    if (reportType === "balanceSheet") {
      setShowStartDate(false);
      setShowEndDate(true);
    } else if (reportType === "profitLoss") {
      setShowStartDate(true);
      setShowEndDate(true);
    } else {
      setShowStartDate(false);
      setShowEndDate(false);
    }
  }, [reportType]);

  const columnDefinition = useMemo(() => {
    if (reportData.length > 0) {
      switch (reportType) {
        case "loanTape":
          return loanTapeCols;
        case "balanceSheet":
          return balanceSheetCols;
        case "profitLoss":
          return profitLossCols;
        case "campaignSummary":
          return campaignSummaryCols;
        case "payoutSummary":
          return payoutSummaryCols;
        case "invoiceSummary":
          return invoiceSummaryCols;
        case "invoiceSettlementSummary":
          return invoiceSettlementSummaryCols;
        case "overdueInvoiceSummary":
          return overdueInvoiceSummaryCols;
        default:
          return [];
      }
    } else return [];
  }, [reportData, reportData]);

  return (
    <>
      <MonetViewTitle>Platform reporting</MonetViewTitle>
      <p className="text-sm">Run pre-built reports made by MONET or view, export and analyse data through the use of the summary reports that are available to you below</p>
      <form className="flex flex-row gap-4 flex-wrap" onSubmit={methods.handleSubmit(onSubmit)}>
        <div className="w-full max-w-[600px]">
          <Controller
            control={methods.control}
            name="reportType"
            rules={{
              required: true,
            }}
            render={({ field: { ...inputProps } }) => (
              <TailwindSelectInput
                inputProps={inputProps}
                label="Report"
                options={[
                  { label: "Loan Tape", value: "loanTape", description: "Report showing all invoices with active or settled funding to date" },
                  {
                    label: "Balance sheet",
                    value: "balanceSheet",
                    description: "Report showing all funded invoices that are active or overdue. Up to the date selected",
                  },
                  {
                    label: "Profit & loss",
                    value: "profitLoss",
                    description: "Report showing all campaigns with funding that have been settled and where a payout has been paid within the date range",
                  },
                  { label: "Overdue invoices summary", value: "overdueInvoiceSummary", description: "Lists all overdue invoices" },
                  { label: "Invoice settlement summary", value: "invoiceSettlementSummary", description: "Lists all settled invoices" },
                  { label: "Campaign summary", value: "campaignSummary", description: "Lists all campaigns" },
                  { label: "Payout summary", value: "payoutSummary", description: "Lists all payouts" },
                  { label: "Invoice summary", value: "invoiceSummary", description: "Lists all invoices" },
                ]}
                placeholder="Please select a report to generate..."
                disabled={methods.formState.isSubmitting}
              />
            )}
          />
        </div>
        <div className="flex flex-col sm:flex-row gap-4 items-end flex-1">
          {showStartDate && (
            <div className="w-full min-w-[200px] max-w-[600px]">
              <Controller
                control={methods.control}
                name="startDate"
                rules={{
                  required: ["profitLoss"].includes(reportType) ? true : false,
                }}
                render={({ field: { ...inputProps } }) => (
                  <MonetDatePicker
                    key="startDate"
                    inputProps={inputProps}
                    disabled={methods.formState.isSubmitting}
                    label="Start date"
                    pickerOptions={{
                      mode: "single",
                    }}
                    error={methods.formState.errors.startDate?.message as string}
                  />
                )}
              />
            </div>
          )}
          {showEndDate && (
            <div className="w-full min-w-[200px] max-w-[600px]">
              <Controller
                control={methods.control}
                name="endDate"
                rules={{
                  required: ["balanceSheet", "profitLoss"].includes(reportType) ? true : false,
                }}
                render={({ field: { ...inputProps } }) => (
                  <MonetDatePicker
                    key="endDate"
                    inputProps={inputProps}
                    disabled={methods.formState.isSubmitting}
                    label="End date"
                    pickerOptions={{
                      minDate: startDate ? dayjs(startDate).add(1, "day").format("YYYY-MM-DD") : undefined,
                      maxDate: dayjs().toDate(),
                      mode: "single",
                    }}
                    error={methods.formState.errors.endDate?.message as string}
                  />
                )}
              />
            </div>
          )}
          <MonetButton type="submit" disabled={loading || !methods.formState.isValid} className="whitespace-nowrap w-full sm:w-fit">
            Generate report
          </MonetButton>
        </div>
      </form>
      <MonetCard className="min-h-[650px] h-[100%]">
        <StyledDataGrid
          pageSizeOptions={[15, 25, 50, 100]}
          rows={reportData}
          columns={columnDefinition as any}
          loading={loading}
          columnVisibilityModel={columnVisibilityModel}
          onColumnVisibilityModelChange={(newModel) => setColumnVisibilityModel(newModel)}
          slots={{ toolbar: GridToolbar }}
          getRowId={(row) => row.rowId}
          initialState={{
            pagination: {
              paginationModel: { pageSize: 15, page: 0 },
            },
          }}
        />
      </MonetCard>
    </>
  );
};

export default Reports;

import { Money, MoneyStorage, formatStorageType } from "@monet-money/money-type";
import { Percentage } from "@monet-money/percentage-type";
import { GridColDef, GridRenderCellParams } from "@mui/x-data-grid";
import dayjs from "dayjs";
import { DEFAULT_DATE_FORMAT } from "shared/logic/calculate";
import { SYSTEM_ALLOWED_CURRENCIES } from "shared/utils/currency";
import { invoiceFundingStatuses, invoiceStatuses } from "shared/utils/invoice";

type InvoiceData = {
  rowId: string;
  partnerId: string;
  campaignId: string;
  campaignName: string;
  invoiceId: string;
  invoiceNumber: string;
  invoiceStatus: string;
  fundingStatus: string;
  durationFromApprovalDate: number;
  dueDate: string;
  daysUntilDue: number;
  invoiceCurrency: string;
  invoiceValue: MoneyStorage;
  fundingLimit: MoneyStorage;
  advanceRate: number;
  daysFunded: number;
  fundsAdvanced: MoneyStorage;
  advancedPercentage: number;
  numberOfAdvances: number;
  monetFees: MoneyStorage;
  partnerFees: MoneyStorage;
  lateFee: MoneyStorage;
  totalFee: MoneyStorage;
  approvedBy: string;
  approvalDate: string;
  underwritingNotes: string;
  experianRiskScore: string;
  wiserfundingRiskScore: string;
  createdAt: string;
};

export const loanTapeCols: GridColDef<InvoiceData>[] = [
  {
    field: "campaignName",
    headerName: "Campaign name",
    type: "string",
    width: 200,
  },
  {
    field: "invoiceNumber",
    headerName: "Invoice number",
    type: "string",
    minWidth: 200,
  },
  {
    field: "invoiceStatus",
    headerName: "Invoice status",
    type: "singleSelect",
    valueOptions: invoiceStatuses,
    minWidth: 200,
  },
  {
    field: "fundingStatus",
    headerName: "Funding status",
    type: "singleSelect",
    valueOptions: invoiceFundingStatuses,
    minWidth: 200,
  },
  {
    field: "durationFromApprovalDate",
    headerName: "Invoice duration (days)",
    type: "number",
    minWidth: 200,
    description: "Duration of the invoice from approval date to due date",
  },
  {
    field: "dueDate",
    headerName: "Due date",
    type: "string",
    minWidth: 200,
    valueGetter: (value, row) => (value ? dayjs(value, DEFAULT_DATE_FORMAT).toDate() : undefined),
    valueFormatter: (value, row) => (value ? dayjs(value).format(DEFAULT_DATE_FORMAT) : undefined),
  },
  {
    field: "daysUntilDue",
    headerName: "Days until due",
    type: "number",
    minWidth: 200,
  },
  {
    field: "invoiceCurrency",
    headerName: "Invoice currency",
    type: "singleSelect",
    valueOptions: SYSTEM_ALLOWED_CURRENCIES,
    minWidth: 200,
  },
  {
    field: "invoiceValue",
    headerName: "Invoice value",
    type: "number",
    minWidth: 200,
    valueGetter: (value, row) => {
      return Number(Money.fromStorageType(value).format(false, false));
    },
    renderCell: (params: GridRenderCellParams<InvoiceData>) => {
      return formatStorageType(params.row.invoiceValue as MoneyStorage);
    },
  },
  {
    field: "fundingLimit",
    headerName: "Funding limit",
    type: "number",
    minWidth: 200,
    valueGetter: (value, row) => {
      return Number(Money.fromStorageType(value).format(false, false));
    },
    renderCell: (params: GridRenderCellParams<InvoiceData>) => {
      return formatStorageType(params.row.fundingLimit as MoneyStorage);
    },
  },
  {
    field: "advanceRate",
    headerName: "Advance rate",
    type: "number",
    minWidth: 200,
    renderCell: (params: GridRenderCellParams<InvoiceData>) => {
      return params.row.advanceRate ? Percentage.fromStorageValue(params.row.advanceRate).format() : undefined;
    },
  },
  {
    field: "daysFunded",
    headerName: "Days funded",
    type: "number",
    minWidth: 200,
  },
  {
    field: "fundsAdvanced",
    headerName: "Funds advanced",
    type: "number",
    minWidth: 200,
    valueGetter: (value, row) => {
      return Number(Money.fromStorageType(value).format(false, false));
    },
    renderCell: (params: GridRenderCellParams<InvoiceData>) => {
      return formatStorageType(params.row.fundsAdvanced as MoneyStorage);
    },
  },
  {
    field: "advancedPercentage",
    headerName: "Advanced percentage",
    type: "number",
    minWidth: 200,
    valueGetter: (value, row) => {
      return new Percentage(value).format(true);
    },
    renderCell: (params: GridRenderCellParams<InvoiceData>) => {
      return new Percentage(params.row.advancedPercentage).format();
    },
  },
  {
    field: "numberOfAdvances",
    headerName: "No. of advances",
    type: "number",
    minWidth: 200,
  },
  {
    field: "monetFees",
    headerName: "MONET fees",
    type: "number",
    minWidth: 200,
    valueGetter: (value, row) => {
      return Number(Money.fromStorageType(value).format(false, false));
    },
    renderCell: (params: GridRenderCellParams<InvoiceData>) => {
      return formatStorageType(params.row.monetFees as MoneyStorage);
    },
  },
  {
    field: "partnerFees",
    headerName: "Agency fees",
    type: "number",
    minWidth: 200,
    valueGetter: (value, row) => {
      return Number(Money.fromStorageType(value).format(false, false));
    },
    renderCell: (params: GridRenderCellParams<InvoiceData>) => {
      return formatStorageType(params.row.partnerFees as MoneyStorage);
    },
  },
  {
    field: "lateFee",
    headerName: "Late fees",
    type: "number",
    minWidth: 200,
    valueGetter: (value, row) => {
      return Number(Money.fromStorageType(value).format(false, false));
    },
    renderCell: (params: GridRenderCellParams<InvoiceData>) => {
      return formatStorageType(params.row.lateFee as MoneyStorage);
    },
  },
  {
    field: "totalFee",
    headerName: "Total fees",
    type: "number",
    minWidth: 200,
    valueGetter: (value, row) => {
      return Number(Money.fromStorageType(value).format(false, false));
    },
    renderCell: (params: GridRenderCellParams<InvoiceData>) => {
      return formatStorageType(params.row.totalFee as MoneyStorage);
    },
  },
  {
    field: "approvedBy",
    headerName: "Approved By",
    type: "string",
    minWidth: 200,
  },
  {
    field: "approvalDate",
    headerName: "Approval date",
    type: "date",
    minWidth: 200,
    valueGetter: (value, row) => dayjs(value, DEFAULT_DATE_FORMAT).toDate(),
    valueFormatter: (value, row) => dayjs(value).format(DEFAULT_DATE_FORMAT),
  },
  {
    field: "underwritingNotes",
    headerName: "Underwriting notes",
    type: "string",
    minWidth: 200,
    valueGetter: (value: any, row) => value.note,
    valueFormatter: (value: any, row) => value.note,
  },
  {
    field: "experianRiskScore",
    headerName: "Experian risk score",
    type: "string",
    minWidth: 200,
  },
  {
    field: "wiserfundingRiskScore",
    headerName: "Wiserfunding risk score",
    type: "string",
    minWidth: 200,
  },
  {
    field: "partnerId",
    headerName: "Partner ID",
    type: "string",
    width: 200,
  },
  {
    field: "campaignId",
    headerName: "Campaign ID",
    type: "string",
    width: 200,
  },
  {
    field: "invoiceId",
    headerName: "Invoice ID",
    type: "string",
    minWidth: 200,
  },
  {
    field: "createdAt",
    headerName: "Created at",
    type: "date",
    minWidth: 200,
    valueGetter: (value, row) => (value ? dayjs(value, DEFAULT_DATE_FORMAT).toDate() : undefined),
    valueFormatter: (value, row) => (value ? dayjs(value).format(DEFAULT_DATE_FORMAT) : undefined),
  },
];

import { Money, MoneyStorage, formatStorageType } from "@monet-money/money-type";
import { GridColDef, GridRenderCellParams } from "@mui/x-data-grid";
import dayjs from "dayjs";
import { DEFAULT_DATE_FORMAT } from "shared/logic/calculate";
import { SYSTEM_ALLOWED_CURRENCIES } from "shared/utils/currency";
import { invoiceFundingStatuses, invoiceStatuses } from "shared/utils/invoice";

type BalanceSheetData = {
  rowId: string;
  partnerId: string;
  campaignId: string;
  campaignName: string;
  invoiceId: string;
  invoiceNumber: string;
  invoiceStatus: string;
  fundingStatus: string;
  dueDate: string;
  daysUntilDue: number;
  invoiceCurrency: string;
  invoiceValue: MoneyStorage;
  fundedAmount: MoneyStorage;
  numberOfAdvancesVendor: number;
  fundsAdvancedVendor: MoneyStorage;
  monetFeesVendor: MoneyStorage;
  partnerFeesVendor: MoneyStorage;
  numberOfAdvancesWithdrawal: number;
  fundsAdvancedWithdrawal: MoneyStorage;
  monetFeesVendorWithdrawal: MoneyStorage;
  partnerFeesVendorWithdrawal: MoneyStorage;
  totalLateFees: MoneyStorage;
  totalMonetFees: MoneyStorage;
  totalPartnerFees: MoneyStorage;
  totalFees: MoneyStorage;
  totalPartnerSubsidy: MoneyStorage;
  totalAdvanced: MoneyStorage;
  residual: MoneyStorage;
};

export const balanceSheetCols: GridColDef<BalanceSheetData>[] = [
  {
    field: "campaignName",
    headerName: "Campaign name",
    type: "string",
    width: 200,
  },
  {
    field: "invoiceNumber",
    headerName: "Invoice number",
    type: "string",
    minWidth: 200,
  },
  {
    field: "invoiceStatus",
    headerName: "Invoice status",
    type: "singleSelect",
    valueOptions: invoiceStatuses,
    minWidth: 200,
  },
  {
    field: "fundingStatus",
    headerName: "Funding status",
    type: "singleSelect",
    valueOptions: invoiceFundingStatuses,
    minWidth: 200,
  },
  {
    field: "dueDate",
    headerName: "Due date",
    type: "string",
    minWidth: 200,
    valueGetter: (value, row) => (value ? dayjs(value, DEFAULT_DATE_FORMAT).toDate() : undefined),
    valueFormatter: (value, row) => (value ? dayjs(value).format(DEFAULT_DATE_FORMAT) : undefined),
  },
  {
    field: "daysUntilDue",
    headerName: "Days until due",
    type: "number",
    minWidth: 200,
  },
  {
    field: "invoiceCurrency",
    headerName: "Invoice currency",
    type: "singleSelect",
    valueOptions: SYSTEM_ALLOWED_CURRENCIES,
    minWidth: 200,
  },
  {
    field: "invoiceValue",
    headerName: "Invoice value",
    type: "number",
    minWidth: 200,
    valueGetter: (value, row) => {
      return Number(Money.fromStorageType(value).format(false, false));
    },
    renderCell: (params: GridRenderCellParams<BalanceSheetData>) => {
      return formatStorageType(params.row.invoiceValue as MoneyStorage);
    },
  },
  {
    field: "fundedAmount",
    headerName: "Funded amount",
    type: "number",
    minWidth: 200,
    valueGetter: (value, row) => {
      return Number(Money.fromStorageType(value).format(false, false));
    },
    renderCell: (params: GridRenderCellParams<BalanceSheetData>) => {
      return formatStorageType(params.row.fundedAmount as MoneyStorage);
    },
  },
  {
    field: "numberOfAdvancesVendor",
    headerName: "No. of vendor advances",
    type: "number",
    minWidth: 250,
  },
  {
    field: "fundsAdvancedVendor",
    headerName: "Funds advanced (vendor)",
    type: "number",
    minWidth: 250,
    valueGetter: (value, row) => {
      return Number(Money.fromStorageType(value).format(false, false));
    },
    renderCell: (params: GridRenderCellParams<BalanceSheetData>) => {
      return formatStorageType(params.row.fundsAdvancedVendor as MoneyStorage);
    },
  },
  {
    field: "monetFeesVendor",
    headerName: "MONET fees (vendor)",
    type: "number",
    minWidth: 250,
    valueGetter: (value, row) => {
      return Number(Money.fromStorageType(value).format(false, false));
    },
    renderCell: (params: GridRenderCellParams<BalanceSheetData>) => {
      return formatStorageType(params.row.monetFeesVendor as MoneyStorage);
    },
  },
  {
    field: "partnerFeesVendor",
    headerName: "Agency fees (vendor)",
    type: "number",
    minWidth: 250,
    valueGetter: (value, row) => {
      return Number(Money.fromStorageType(value).format(false, false));
    },
    renderCell: (params: GridRenderCellParams<BalanceSheetData>) => {
      return formatStorageType(params.row.partnerFeesVendor as MoneyStorage);
    },
  },
  {
    field: "numberOfAdvancesWithdrawal",
    headerName: "No. of withdrawal advances",
    type: "number",
    minWidth: 250,
  },
  {
    field: "fundsAdvancedWithdrawal",
    headerName: "Funds advanced (withdrawal)",
    type: "number",
    minWidth: 250,
    valueGetter: (value, row) => {
      return Number(Money.fromStorageType(value).format(false, false));
    },
    renderCell: (params: GridRenderCellParams<BalanceSheetData>) => {
      return formatStorageType(params.row.fundsAdvancedWithdrawal as MoneyStorage);
    },
  },
  {
    field: "monetFeesVendorWithdrawal",
    headerName: "MONET fees (withdrawal)",
    type: "number",
    minWidth: 250,
    valueGetter: (value, row) => {
      return Number(Money.fromStorageType(value).format(false, false));
    },
    renderCell: (params: GridRenderCellParams<BalanceSheetData>) => {
      return formatStorageType(params.row.monetFeesVendorWithdrawal as MoneyStorage);
    },
  },
  {
    field: "partnerFeesVendorWithdrawal",
    headerName: "Agency fees (withdrawal)",
    type: "number",
    minWidth: 250,
    valueGetter: (value, row) => {
      return Number(Money.fromStorageType(value).format(false, false));
    },
    renderCell: (params: GridRenderCellParams<BalanceSheetData>) => {
      return formatStorageType(params.row.partnerFeesVendorWithdrawal as MoneyStorage);
    },
  },
  {
    field: "totalMonetFees",
    headerName: "Total MONET fees",
    type: "number",
    minWidth: 200,
    valueGetter: (value, row) => {
      return Number(Money.fromStorageType(value).format(false, false));
    },
    renderCell: (params: GridRenderCellParams<BalanceSheetData>) => {
      return formatStorageType(params.row.totalMonetFees as MoneyStorage);
    },
  },
  {
    field: "totalLateFees",
    headerName: "Total Late fees",
    type: "number",
    minWidth: 200,
    valueGetter: (value, row) => {
      return Number(Money.fromStorageType(value).format(false, false));
    },
    renderCell: (params: GridRenderCellParams<BalanceSheetData>) => {
      return formatStorageType(params.row.totalLateFees as MoneyStorage);
    },
  },
  {
    field: "totalPartnerFees",
    headerName: "Total agency fees",
    type: "number",
    minWidth: 200,
    valueGetter: (value, row) => {
      return Number(Money.fromStorageType(value).format(false, false));
    },
    renderCell: (params: GridRenderCellParams<BalanceSheetData>) => {
      return formatStorageType(params.row.totalPartnerFees as MoneyStorage);
    },
  },
  {
    field: "totalFees",
    headerName: "Total fees",
    type: "number",
    minWidth: 200,
    valueGetter: (value, row) => {
      return Number(Money.fromStorageType(value).format(false, false));
    },
    renderCell: (params: GridRenderCellParams<BalanceSheetData>) => {
      return formatStorageType(params.row.totalFees as MoneyStorage);
    },
  },
  {
    field: "totalPartnerSubsidy",
    headerName: "Total partner subsidy",
    type: "number",
    minWidth: 200,
    valueGetter: (value, row) => {
      return Number(Money.fromStorageType(value).format(false, false));
    },
    renderCell: (params: GridRenderCellParams<BalanceSheetData>) => {
      return formatStorageType(params.row.totalPartnerSubsidy as MoneyStorage);
    },
  },
  {
    field: "totalAdvanced",
    headerName: "Total advanced",
    type: "number",
    minWidth: 200,
    valueGetter: (value, row) => {
      return Number(Money.fromStorageType(value).format(false, false));
    },
    renderCell: (params: GridRenderCellParams<BalanceSheetData>) => {
      return formatStorageType(params.row.totalAdvanced as MoneyStorage);
    },
  },
  {
    field: "residual",
    headerName: "Residual",
    type: "number",
    minWidth: 200,
    valueGetter: (value, row) => {
      return Number(Money.fromStorageType(value).format(false, false));
    },
    renderCell: (params: GridRenderCellParams<BalanceSheetData>) => {
      return formatStorageType(params.row.residual as MoneyStorage);
    },
  },
  {
    field: "partnerId",
    headerName: "Partner ID",
    type: "string",
    width: 200,
  },
  {
    field: "campaignId",
    headerName: "Campaign ID",
    type: "string",
    width: 200,
  },
  {
    field: "invoiceId",
    headerName: "Invoice ID",
    type: "string",
    minWidth: 200,
  },
];

import { Money, MoneyStorage, formatStorageType } from "@monet-money/money-type";
import { Percentage } from "@monet-money/percentage-type";
import { GridColDef, GridRenderCellParams } from "@mui/x-data-grid";
import dayjs from "dayjs";
import { DEFAULT_DATE_FORMAT } from "shared/logic/calculate";
import { SYSTEM_ALLOWED_CURRENCIES } from "shared/utils/currency";
import { invoiceFundingStatuses, invoiceStatuses } from "shared/utils/invoice";

type InvoiceData = {
  rowId: string;
  partnerId: string;
  campaignId: string;
  campaignName: string;
  invoiceId: string;
  invoiceNumber: string;
  invoiceStatus: string;
  fundingStatus: string;
  dueDate: string;
  invoiceCurrency: string;
  invoiceValue: MoneyStorage;
  fundedAmount: MoneyStorage;
  advanceRate: number;
  monetFees: MoneyStorage;
  agencyFees: MoneyStorage;
  daysLate: number;
  lateFee: MoneyStorage;
  principal: MoneyStorage;
  residual: MoneyStorage;
  settlementDate: string;
  settlementBy: string;
};

export const invoiceSettlementSummaryCols: GridColDef<InvoiceData>[] = [
  {
    field: "campaignName",
    headerName: "Campaign name",
    type: "string",
    width: 200,
  },
  {
    field: "invoiceNumber",
    headerName: "Invoice number",
    type: "string",
    minWidth: 150,
  },
  {
    field: "invoiceStatus",
    headerName: "Invoice status",
    type: "singleSelect",
    valueOptions: invoiceStatuses,
    minWidth: 150,
  },
  {
    field: "fundingStatus",
    headerName: "Funding status",
    type: "singleSelect",
    valueOptions: invoiceFundingStatuses,
    minWidth: 150,
  },
  {
    field: "dueDate",
    headerName: "Due date",
    type: "string",
    minWidth: 150,
    valueGetter: (value, row) => (value ? dayjs(value, DEFAULT_DATE_FORMAT).toDate() : undefined),
    valueFormatter: (value, row) => (value ? dayjs(value).format(DEFAULT_DATE_FORMAT) : undefined),
  },
  {
    field: "invoiceCurrency",
    headerName: "Invoice currency",
    type: "singleSelect",
    valueOptions: SYSTEM_ALLOWED_CURRENCIES,
    minWidth: 150,
  },
  {
    field: "invoiceValue",
    headerName: "Invoice value",
    type: "number",
    minWidth: 150,
    valueGetter: (value, row) => {
      return Number(Money.fromStorageType(value).format(false, false));
    },
    renderCell: (params: GridRenderCellParams<InvoiceData>) => {
      return formatStorageType(params.row.invoiceValue as MoneyStorage);
    },
  },
  {
    field: "fundedAmount",
    headerName: "Funded amount",
    type: "number",
    minWidth: 150,
    valueGetter: (value, row) => {
      return Number(Money.fromStorageType(value).format(false, false));
    },
    renderCell: (params: GridRenderCellParams<InvoiceData>) => {
      return formatStorageType(params.row.fundedAmount as MoneyStorage);
    },
  },
  {
    field: "advanceRate",
    headerName: "Advance rate",
    type: "number",
    minWidth: 150,
    renderCell: (params: GridRenderCellParams<InvoiceData>) => {
      return params.row.advanceRate ? Percentage.fromStorageValue(params.row.advanceRate).format() : undefined;
    },
  },
  {
    field: "monetFees",
    headerName: "Associated MONET fees",
    type: "number",
    minWidth: 200,
    valueGetter: (value, row) => {
      return row.invoiceStatus === "SETTLED" ? Number(Money.fromStorageType(value).format(false, false)) : undefined;
    },
    renderCell: (params: GridRenderCellParams<InvoiceData>) => {
      return params.row.invoiceStatus === "SETTLED" ? Money.fromStorageType(params.row.monetFees).format() : undefined;
    },
    description: "The total sum of MONET fees for each payout associated with this invoice",
  },
  {
    field: "agencyFees",
    headerName: "Associated agency fees",
    type: "number",
    minWidth: 200,
    valueGetter: (value, row) => {
      return row.invoiceStatus === "SETTLED" ? Number(Money.fromStorageType(value).format(false, false)) : undefined;
    },
    renderCell: (params: GridRenderCellParams<InvoiceData>) => {
      return params.row.invoiceStatus === "SETTLED" ? Money.fromStorageType(params.row.agencyFees).format() : undefined;
    },
    description: "The total sum of agency fees for each payout associated with this invoice",
  },
  {
    field: "daysLate",
    headerName: "Days late",
    type: "number",
    minWidth: 150,
  },
  {
    field: "lateFee",
    headerName: "Late fee",
    type: "number",
    minWidth: 150,
    valueGetter: (value, row) => {
      return row.invoiceStatus === "SETTLED" ? Number(Money.fromStorageType(value).format(false, false)) : undefined;
    },
    renderCell: (params: GridRenderCellParams<InvoiceData>) => {
      return params.row.invoiceStatus === "SETTLED" ? Money.fromStorageType(params.row.lateFee).format() : undefined;
    },
  },
  {
    field: "principal",
    headerName: "Principal",
    type: "number",
    minWidth: 150,
    valueGetter: (value, row) => {
      return row.invoiceStatus === "SETTLED" ? Number(Money.fromStorageType(value).format(false, false)) : undefined;
    },
    renderCell: (params: GridRenderCellParams<InvoiceData>) => {
      return params.row.invoiceStatus === "SETTLED" ? Money.fromStorageType(params.row.principal).format() : undefined;
    },
  },
  {
    field: "residual",
    headerName: "Residual",
    type: "number",
    minWidth: 200,
    valueGetter: (value, row) => {
      return row.invoiceStatus === "SETTLED" ? Number(Money.fromStorageType(value).format(false, false)) : undefined;
    },
    renderCell: (params: GridRenderCellParams<InvoiceData>) => {
      return params.row.invoiceStatus === "SETTLED" ? Money.fromStorageType(params.row.residual).format() : undefined;
    },
  },
  {
    field: "settlementDate",
    headerName: "Settlement date",
    type: "date",
    minWidth: 200,
    valueGetter: (value, row) => (value ? dayjs(value, DEFAULT_DATE_FORMAT).toDate() : undefined),
    valueFormatter: (value, row) => (value ? dayjs(value).format(DEFAULT_DATE_FORMAT) : undefined),
  },
  {
    field: "settlementBy",
    headerName: "settlement by",
    type: "string",
    minWidth: 200,
  },
  {
    field: "partnerId",
    headerName: "Partner ID",
    type: "string",
    width: 200,
  },
  {
    field: "campaignId",
    headerName: "Campaign ID",
    type: "string",
    width: 200,
  },
  {
    field: "invoiceId",
    headerName: "Invoice ID",
    type: "string",
    width: 150,
  },
  {
    field: "createdAt",
    headerName: "Created at",
    type: "date",
    minWidth: 200,
    valueGetter: (value, row) => (value ? dayjs(value, DEFAULT_DATE_FORMAT).toDate() : undefined),
    valueFormatter: (value, row) => (value ? dayjs(value).format(DEFAULT_DATE_FORMAT) : undefined),
  },
];

import { Money, MoneyStorage, formatStorageType } from "@monet-money/money-type";
import { GridColDef, GridRenderCellParams } from "@mui/x-data-grid";
import dayjs from "dayjs";
import { SYSTEM_ALLOWED_CURRENCIES } from "shared/utils/currency";
import { DEFAULT_DATE_FORMAT } from "utils/date";
import { campaignStatuses } from "views/campaigns/utils";

type ProfitLossData = {
  rowId: string;
  partnerId: string;
  campaignId: string;
  campaignName: string;
  campaignStatus: string;
  campaignCurrency: string;
  campaignValue: MoneyStorage;
  fundedAmount: MoneyStorage;
  totalAdvanced: MoneyStorage;
  totalMonetFees: MoneyStorage;
  totalPartnerFees: MoneyStorage;
  totalLateFees: MoneyStorage;
  totalPartnerSubsidy: MoneyStorage;
  totalOverFunding: MoneyStorage;
  createdAt: string;
};

export const profitLossCols: GridColDef<ProfitLossData>[] = [
  {
    field: "campaignName",
    headerName: "Campaign name",
    type: "string",
    width: 200,
  },
  {
    field: "campaignStatus",
    headerName: "Status",
    type: "singleSelect",
    minWidth: 200,
    valueOptions: campaignStatuses,
  },
  {
    field: "campaignCurrency",
    headerName: "Campaign currency",
    type: "singleSelect",
    valueOptions: SYSTEM_ALLOWED_CURRENCIES,
    minWidth: 200,
  },
  {
    field: "campaignValue",
    headerName: "Campaign value",
    type: "number",
    minWidth: 200,
    valueGetter: (value, row) => {
      return Number(Money.fromStorageType(value).format(false, false));
    },
    renderCell: (params: GridRenderCellParams<ProfitLossData>) => {
      return formatStorageType(params.row.campaignValue as MoneyStorage);
    },
  },
  {
    field: "fundedAmount",
    headerName: "Funded amount",
    type: "number",
    minWidth: 200,
    valueGetter: (value, row) => {
      return Number(Money.fromStorageType(value).format(false, false));
    },
    renderCell: (params: GridRenderCellParams<ProfitLossData>) => {
      return formatStorageType(params.row.fundedAmount as MoneyStorage);
    },
  },
  {
    field: "totalAdvanced",
    headerName: "Total advanced",
    type: "number",
    minWidth: 200,
    valueGetter: (value, row) => {
      return Number(Money.fromStorageType(value).format(false, false));
    },
    renderCell: (params: GridRenderCellParams<ProfitLossData>) => {
      return formatStorageType(params.row.totalAdvanced as MoneyStorage);
    },
  },
  {
    field: "totalMonetFees",
    headerName: "Total MONET fees",
    type: "number",
    minWidth: 200,
    valueGetter: (value, row) => {
      return Number(Money.fromStorageType(value).format(false, false));
    },
    renderCell: (params: GridRenderCellParams<ProfitLossData>) => {
      return formatStorageType(params.row.totalMonetFees as MoneyStorage);
    },
  },
  {
    field: "totalPartnerFees",
    headerName: "Total agency fees",
    type: "number",
    minWidth: 200,
    valueGetter: (value, row) => {
      return Number(Money.fromStorageType(value).format(false, false));
    },
    renderCell: (params: GridRenderCellParams<ProfitLossData>) => {
      return formatStorageType(params.row.totalPartnerFees as MoneyStorage);
    },
  },
  {
    field: "totalLateFees",
    headerName: "Total late fees",
    type: "number",
    minWidth: 200,
    valueGetter: (value, row) => {
      return Number(Money.fromStorageType(value).format(false, false));
    },
    renderCell: (params: GridRenderCellParams<ProfitLossData>) => {
      return formatStorageType(params.row.totalLateFees as MoneyStorage);
    },
  },
  {
    field: "totalPartnerSubsidy",
    headerName: "Total agency subsidy",
    type: "number",
    minWidth: 200,
    valueGetter: (value, row) => {
      return Number(Money.fromStorageType(value).format(false, false));
    },
    renderCell: (params: GridRenderCellParams<ProfitLossData>) => {
      return formatStorageType(params.row.totalPartnerSubsidy as MoneyStorage);
    },
  },
  {
    field: "totalOverFunding",
    headerName: "Total over invoice value",
    type: "number",
    minWidth: 200,
    description: "This is the total value exceeded of the invoice value",
    valueGetter: (value, row) => {
      return Number(Money.fromStorageType(value).format(false, false));
    },
    renderCell: (params: GridRenderCellParams<ProfitLossData>) => {
      return formatStorageType(params.row.totalOverFunding as MoneyStorage);
    },
  },
  {
    field: "partnerId",
    headerName: "Partner ID",
    type: "string",
    width: 200,
  },
  {
    field: "campaignId",
    headerName: "Campaign ID",
    type: "string",
    width: 200,
  },
  {
    field: "createdAt",
    headerName: "Campaign created date",
    type: "date",
    minWidth: 200,
    valueGetter: (value, row) => (value ? dayjs(value, DEFAULT_DATE_FORMAT).toDate() : undefined),
    valueFormatter: (value, row) => (value ? dayjs(value).format(DEFAULT_DATE_FORMAT) : undefined),
  },
];
